<template>
	<v-row class="dashboard-wrapper">
		<v-col
			cols="12"
			style="
				max-height: calc(100vh - 100px);
				min-height: calc(100vh - 100px);
				height: calc(100vh - 100px);
				overflow-y: scroll;
			"
		>
			<div class="mx-4 mb-3 mt-3">
				<table class="d-btn">
					<tr>
						<td class="pr-2">
							<v-btn
								v-on:click="do_action('upload-file')"
								color="blue darken-4 white--text"
								tile
								depressed
							>
								<v-icon class="mr-2">mdi-cloud-upload-outline</v-icon> Upload files
							</v-btn>
						</td>
						<td class="px-2">
							<v-btn v-on:click="do_action('upload-folder')" tile depressed>
								<v-icon class="mr-2">mdi-folder-upload-outline</v-icon> Upload folder
							</v-btn>
						</td>
						<td class="px-2">
							<v-btn v-on:click="do_action('create-folder')" tile depressed>
								<v-icon class="mr-2">mdi-folder-plus-outline</v-icon> Create new folder
							</v-btn>
						</td>
					</tr>
				</table>
			</div>
			<v-row class="dash-content-row">
				<v-col cols="12">
					<v-layout>
						<v-flex md6 class="mx-4">
							<dashboard-listing label="Recent" endpoint="recent"></dashboard-listing>
						</v-flex>
						<v-flex md6 class="mx-4">
							<dashboard-listing label="Starred" endpoint="starred"></dashboard-listing>
						</v-flex>
					</v-layout>
				</v-col>
				<v-col v-if="false" cols="12">
					<v-layout>
						<v-flex md6 class="mx-4">
							<div class="border-light-grey py-2 dashboard-listing activity-log">
								<p class="mx-5 mb-1 font-level-3-bold text-uppercase">Activity Log</p>
								<div style="max-height: 400px; min-height: 400px; height: 400px; overflow-y: scroll">
									<div class="activity-details-wrapper px-3 mx-3">
										<div class="h4 py-2" v-if="isNotEmpty(file_log)">
											<div
												v-for="(row, index) in file_log"
												:key="`activity-log-${index}`"
												class="activity-info mb-5"
											>
												<div class="date my-3">{{ row.month }}</div>
												<v-flex
													class="d-flex cm-flex mb-4"
													v-for="(log, rindex) in row.rows"
													:class="{ 'border-bottom': row.rows[rindex + 1] }"
													:key="`${index}-${rindex}`"
												>
													<v-img
														class="user-img mr-5"
														max-height="50"
														max-width="50"
														:src="log.created_by.profile"
													></v-img>
													<div class="d-flex flex-column flex-nowrap">
														<span v-html="log.description"></span>
														<span class="text--secondary mb-0 pt-1"
															>Date Time: {{ formatDateTime(log.added_at) }}</span
														>
														<span class="text--secondary mb-0"
															>IP Address: {{ log.ip_address }}
															<v-icon
																small
																class="ml-1 my-0 py-0"
																color="blue darken-4"
																v-on:click="redirect_ip(log.ip_address)"
																>mdi-open-in-new</v-icon
															></span
														>
													</div>
												</v-flex>
											</div>
										</div>
									</div>
								</div>
							</div>
						</v-flex>
						<v-flex md6 class="mx-4"> </v-flex>
					</v-layout>
				</v-col>
			</v-row>
		</v-col>
		<!--end::Dashboard-->
	</v-row>
</template>

<script>
import DashboardListing from "@/view/pages/Dashboard-Listing";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { mapGetters } from "vuex";
import { QUERY } from "@/core/services/store/request.module";
import { SET_UPLOAD_STATUS } from "@/core/services/store/common.module";

export default {
	name: "dashboard",
	data() {
		return {
			right_bar: false,
			model: false,
			open_timeout: null,
			open_timeout_limit: 500,
			file_log: [],
		};
	},
	methods: {
		redirect_ip(ip) {
			const url = `https://whatismyipaddress.com/ip/${ip}`;
			window.open(url, "_blank");
		},
		get_logs() {
			this.$store
				.dispatch(QUERY, { url: `activity-log` })
				.then((response) => {
					this.file_log = response;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		do_action(type) {
			EventBus.$emit(type, true);
		},
		site_logo() {
			const logoObject = this.layoutConfig("self.logo");

			let logo;
			if (typeof logoObject === "string") {
				logo = logoObject;
			}
			if (typeof logoObject === "object") {
				logo = ObjectPath.get(logoObject, "light");
			}
			if (typeof logo === "undefined") {
				const logos = this.layoutConfig("self.logo");
				logo = logos[Object.keys(logos)[0]];
			}
			return process.env.BASE_URL + logo;
		},
	},
	components: {
		"dashboard-listing": DashboardListing,
	},
	mounted() {
		setTimeout(() => {
			this.$store.dispatch(SET_UPLOAD_STATUS, true);
		}, 500);
	},
	beforeDestroy() {
		this.$store.dispatch(SET_UPLOAD_STATUS, false);
	},
	computed: {
		...mapGetters(["layoutConfig"]),
	},
};
</script>
